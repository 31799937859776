
import { ContentHalfNoImage } from "./styles";
import ContentTitle from "@/shared/ContentTitle";
import { LinkAbsolute } from "@/shared/LinkAbsolute";
import { NormalArticle } from "@/widgets/Nota/Templates/stylesModulesGenericNormal";
import { clickContent } from "@/shared/Analitics/GoogleAnalytics4";
import useNewsDataModules from "@/hooks/useNewsDataModules";

const HalfNoImage = (props) => {
  const {
    asPath,
    author = [],
    authorSignature,
    containerName,
    payWallExclusive,
    section = [],
    subtitle,
    title,
    url
  } = useNewsDataModules(props); 

  return (

    <NormalArticle>
      <ContentHalfNoImage>
      <ContentTitle 
       author={author}
       authorSignature={authorSignature}
       title={title}
       subtitle={subtitle}
       section={section}
       payWallExclusive={payWallExclusive}
     />
    <LinkAbsolute 
      href={url} 
      target="_self" 
      aria-label={title} 
      onClick={() => clickContent({contentType: title, contentList: containerName},url,asPath)}
      />
       
      
      </ContentHalfNoImage>

    </NormalArticle>
   

  );
};

export default HalfNoImage;
